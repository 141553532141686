@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;

@media only screen and (max-width:800px) {
    img {
        display:block;
        float:none;
        margin:0 auto 20px !important;
    }
    .social-refs a {
        margin: 20px 10px !important;
    }
    #profile, #furry {
        justify-content: center !important;
    }
}

html, body, .page-content, .wrapper {
    height: 100%;
}

.front-page {
  width: 100%;
  height: 80%;
  display: table;
}

.dots-box {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  max-width: 740px;
  min-height: 522px;
}

.profile-section {
  min-height: 261px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#profile {
  justify-content: flex-start;
}

#profile a {
  margin: 0 0 0 46px;
}

#furry {
  justify-content: flex-end;
}

#furry a {
  margin: 0 46px 0 0;
}

.social-refs a {
  max-height: 96px;
  max-width: 96px;
  overflow: hidden;
}

.social-refs a:hover {
  text-decoration: none;
}

.linkedin {
  color: #0077B5 !important;
}

.facebook {
  color: #3b5998 !important;
}

.mastodon {
  color: #6364FF !important;
}

.github {
  color: #171515 !important;
}

.bluesky {
  color: #3187F7 !important;
}

img.bigdot {
  height: 256px;
  width: 256px;
}

img.left {
  margin: 0 20px 0 0;
}

img.right {
  margin: 0 0 0 20px;
}

.site-header {
  background-color: #fff;
  height: 56px;
  z-index: 10;
}
